import React from 'react';
import {
  jetBlack,
  cleanWhite,
} from '@shipae/components-sandbox/component/colors';
import { media, fonts } from 'src/views/theme';
import styled from 'styled-components';
import {
  FeaturesList as IProps,
} from 'src/infrastructure/gatsby/types/features-list';

const Wrapper = styled.section`
  display: flex;
  ${ media.sm } {
    display: none;
  }
  justify-content: center;
  background: ${ jetBlack() };
`;

const List = styled.ul`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0.4rem 1rem 2rem 1rem;
  max-width: var(--container-width);
  list-style-type: none;
`;

const Feature = styled.li`
  display: flex;
  align-items: center;
  padding: 1.6rem 2rem 0 2rem;
  color: ${ cleanWhite() };
  ${ fonts.bodyl200 };
`;

const FeatureLabel = styled.h3`
  margin: 0 0 0 .8rem;
  white-space: nowrap;
  ${ fonts.bodyl200 };
`;

const FeaturesList: React.FC<IProps> = ({
  sectionId,
  features,
  headingLevel,
}) => (
  <>
    { features
      ? (
        <Wrapper id={sectionId}>
          <List>
            {features.map((feat) => (
              <Feature key={feat.id}>
                <img
                  src={feat.icon?.localFile?.publicURL}
                  alt={feat.icon?.alternativeText || ''}
                  loading="lazy"
                />
                <FeatureLabel as={headingLevel || 'div'}>
                  {feat.label}
                </FeatureLabel>
              </Feature>
            ))}
          </List>
        </Wrapper>
      ) : null }
  </>
);

export default FeaturesList;
